import React, { useState, useEffect } from 'react';
import {Content} from './elements';
import  HomeButton from "../../Components/HomeButton"
function Portfolio() {
    const [bCheckEnabled, setBCheckEnabled] = useState(true);
    const [bFinishCheck, setBFinishCheck] = useState(false);
    const [imgArray, setImgArray] = useState([]);
    const [i, setI] = useState(1);
    let img = new Image();
    useEffect(() => {
        const myInterval = setInterval(loadImage, 1);
        return () => clearInterval(myInterval);
    });

    function loadImage() {
        if (bFinishCheck) {
            return;
        }

        if (bCheckEnabled) {
            setBCheckEnabled(false);
            img = new Image();
            img.onload = fExists;
            img.onerror = fDoesntExist;
            img.src = `imgs/portofolio/${i}.jpg`;
        }
    }

    function fExists() {
        setImgArray([...imgArray, img]);
        setI(i + 1);
        setBCheckEnabled(true);
    }

    function fDoesntExist() {
        setBFinishCheck(true);
    }

    return (
        <>
            <HomeButton/>
            <Content>
                {imgArray.map((img, index) => (
                    <img key={index} src={img.src} />
                ))}
            </Content>
        </>
    );
}

export default Portfolio;
