import styled from "styled-components";

export const Wrapper = styled.div`
    display: block;
    height: 100vh;
	width: 100vw;
    top: 100vh;
	position: relative;
	@media (max-width: 768px) {
		top: 10vh;
	}
`
export const AboutContainer = styled.div`
    width: 50vw;
	margin: auto;
	position: relative;
	font-family: "ABC Social", sans-serif;
	top: 20vh;
	animation: slidein 1s ease-out;
    @keyframes slidein {
	from { 
		opacity: 0;
		bottom: 8vh;
	}
	to   {
		opacity: 1;
		bottom: 10vh;
	}
}
	@media (max-width: 768px) {
		width: 70vw;
	}
`
export const Greeting = styled.div`
    font-size: 4rem;
    font-weight: 900;
	text-align: center;
`
export const About = styled.p`
    display: block;
	padding: 1.5rem;
	width: calc(100% - 3rem);
	margin: auto;
	font-size: 1.5rem;
	line-height: 1.3;
	background-color: rgba(5,12,54,0.20);
	@media (max-width: 768px) {
		font-size: 1.2rem;
	}
`
export const SocialLinks = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 40%;
	margin: auto;
	padding: 20px;
`

export const SocialLink = styled.a`
    display: inline-block;
    text-align: center;
    font-size: 2rem;
    color: #000;
	transition: 0.2s ease;
	&:hover {
		opacity: 0.5;
	}
`
