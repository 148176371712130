import styled from 'styled-components';


export const HomeLinkPic = styled.img`
    width: 10%;
    position: absolute;
    margin: 0px;
    left: 0px;
    top: 0px;
    transform: translate(-25%, -25%);
    transition-duration: 0.5s;
    @media (max-width: 768px) {
        width: 20%;
        transform: translate(0, 0);
        position: fixed;
    }
`;

export const HomeLink = styled.a`
    display: block;
    width: 3vw;
    position: absolute;
    margin: 0px;
    left: 0vw;
    top: 0px;
    text-align: center;
    color: white;
    transition-duration: 0.5s;
    text-decoration: none;
    z-index: 2;
    font-size: 0.9em;
    font-family: 'Nunito Sans', sans-serif;
    &:hover + ${HomeLinkPic} {
        transform: translate(0,0);
    }
    &:hover {
        transform: translate(50%, 70%) scale(2);
    }
    @media (max-width: 768px) {
        transform: translate(50%, 70%) scale(2);
        padding-left: 0.5rem;
        position: fixed;
    }
`;