import styled from 'styled-components';


export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: auto;
    > img {
        width: 90%;
        padding: 5%;    }

`;
