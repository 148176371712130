import React from 'react'
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import {
    Wrapper,
    AboutContainer,
    Greeting,
    About,
    SocialLinks,
    SocialLink
} from './elements'

const greetings = ["Hello!", "Hei!", "God dag", "Hi!", "Heyo", "Greetings", "Guten Tag!", "!مرحبا", "!سلام", "Ç'kemi!", "Γειά σου!", "こんにちは!", "你好!", "नमस्ते!", "안녕하세요!", "Sveiki!", "Alo!", "What's up!", "Ciao!", "Aloha!", "Szia!", "Ahoy!", "สวัสดี!", "Привет!", "Bonjour!", "Hola!", "Salut!", "Dia dhuit!"];

const Fade = styled.div`
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => props.show ? 1 : 0};
`;

export default function AboutSection(props) {
    const [greeting, setGreeting] = useState(greetings[Math.floor(Math.random() * greetings.length)]);
    const [showGreeting, setShowGreeting] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowGreeting(false);
            setTimeout(() => {
                setGreeting(greetings[Math.floor(Math.random() * greetings.length)]);
                setShowGreeting(true);
            }, 500);
        }, 10000);
        return () => clearInterval(interval);
    }, []);

  return (
    <Wrapper id={props.id}>
        <AboutContainer>
            <Fade show={showGreeting}>
                <Greeting>
                    {greeting}
                </Greeting>
            </Fade>
            <About>
            Hei! Mitt navn er Saim. Jeg studerer kybernetikk og robotikk, men for tiden er jeg på utveksling ved MIT. Utenom skole er jeg interessert i fotografi, kunst og skating. I tillegg er jeg svartbelte i taekwondo, og konkurrerer på høyt nivå. På denne siden finner du en CV, tekniske prosjekter, bilder jeg har tatt, og mer. 
            </About>
            <SocialLinks>
                <SocialLink href='https://www.linkedin.com/in/saim-naveed-iqbal/'>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </SocialLink>
                <SocialLink href='https://github.com/saimnaveediqbal'>
                    <FontAwesomeIcon icon={faGithub} />
                </SocialLink>
                <SocialLink href='https://www.instagram.com/saimnaveediqbal/'>
                    <FontAwesomeIcon icon={faInstagram} /> 
                </SocialLink>
                <SocialLink href='mailto:mail@saimiqbal.no'>
                    <FontAwesomeIcon icon={faEnvelope} />
                </SocialLink>
            </SocialLinks>
        </AboutContainer>
    </Wrapper>
  )
}
