import styled from 'styled-components';


export const Image = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`

export const Overlay = styled.div.attrs(props => ({
	background_color: props.background_color,
}))`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: ${props => props.background_color};
	overflow: hidden;
	width: 0;
	height: 100%;
	transition: .5s ease;
	transition-delay: 0.15s;
	z-index: 1;
	@media (max-width: 768px) {
		width: 100%;
	}
`
export const ProjectLink = styled.a`
	position: relative;
    display: block;
    width: 40rem;
    height: 20rem;
	&:hover ${Overlay} {
		width: 100%;
	}
	@media (max-width: 768px) {
		width: 30rem;
		height: 15rem;
	}
`
export const ProjectTitle = styled.div`
	margin: auto;
	position: absolute;
	text-align: left;
	color: white;
	font-size: 2em;
	font-family: "ABC Social", "Nunito Sans", sans-serif;
	font-weight: 900;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	white-space: nowrap;
`
export const ProjectDescription = styled.div`
	margin: auto;
	position: absolute;
	text-align: left;
	color: white;
	font-size: 1.5em;
	font-family: "ABC Social", "Nunito Sans", sans-serif;
	font-weight: 300;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -60%);
	white-space: nowrap;
`
