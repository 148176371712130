import React from 'react'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faGithub, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import ProjectLink from './Components/ProjectLink'
import { Header, Wrapper, SocialLink, SocialLinks, NavLink, FadeContainer, Hero, Navigation } from './elements'

export default function Frontpage() {
  useEffect(() => {
    function handleScroll() {
      const holder = document.querySelector('.fadecontainer');
      if (holder && window.innerWidth >= 768) {
        holder.style.opacity = 1 - window.scrollY / 500;
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
    <Wrapper>
      <div>
      <Header>
        saim iqbal
      </Header>
      <Hero>
      Hei! Mitt navn er Saim. Jeg studerer kybernetikk og robotikk, men for tiden er jeg på utveksling ved MIT. Utenom skole er jeg interessert i fotografi, kunst og skating. I tillegg er jeg svartbelte i taekwondo, og konkurrerer på høyt nivå.
      <br/>
      På denne siden finner du en CV, tekniske prosjekter, bilder jeg har tatt, og mer.
      </Hero>
      <SocialLinks>
        <SocialLink href='https://www.linkedin.com/in/saimnaveediqbal/'>
            <FontAwesomeIcon icon={faLinkedinIn} />
        </SocialLink>
        <SocialLink href='https://github.com/saimnaveediqbal'>
            <FontAwesomeIcon icon={faGithub} />
        </SocialLink>
        <SocialLink href='https://www.instagram.com/saimnaveediqbal/'>
            <FontAwesomeIcon icon={faInstagram} /> 
        </SocialLink>
        <SocialLink href='mailto:mail@saimiqbal.no'>
            <FontAwesomeIcon icon={faEnvelope} />
        </SocialLink>
      </SocialLinks>
      </div>
      <Navigation>
        <NavLink href='#/resume'>cv</NavLink>
        <NavLink href='#/projects'>prosjekter</NavLink>
        <NavLink href='#/portofolio'>bilder</NavLink>
      </Navigation> 
    </Wrapper>
    </>
  )
}
