import React from 'react'
import { HomeLink, HomeLinkPic } from './elements'
export default function HomeButton(props) {
  return (
    <div className={props.className}>
        <HomeLink href="/">
            hjem
        </HomeLink>
        <HomeLinkPic src="/imgs/homepic.png" alt="hjem" />
    </div>
  )
}
