import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: auto;
    font-family: "ABC Social", sans-serif;	
	font-size: 18px;
`;

export const Image = styled.img`
    width: 45%;
    margin: auto;
    display: block;
`;

export const Header = styled.h1`
    font-size: 4em;
    font-family: "ABC Social", sans-serif;
    font-weight: 800;
    font-stretch: expanded;
`;

export const ProjectTitle = styled.h2`
    font-size: 3em;
    font-family: "Catamaran", sans-serif;
    font-weight: 800;
    margin-bottom: 0;
`;

export const ProjectSubtitle = styled.h3`
    font-size: 1.5em;
    margin-top: 0;
    font-style: italic;
`;

export const ProjectSection = styled.h4`
    font-size: 1em;
    font-family: "ABC Social", sans-serif;
    font-weight: 800;
    margin: 0;
`;

export const Date = styled.h5`
    font-family: 'Courier New', Courier, monospace;
    margin: 0;
`;
