import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import Frontpage from './Pages/Frontpage'
import Resume from './Pages/Resume'
import Flow from './Pages/Flowmodoro'
import Portofolio from './Pages/Portofolio'
import Projects from "./Pages/Projects";
import Dustjacket from "./Pages/Dustjacket/src";
import './App.css';


export default function App() {
  return (
      <HashRouter>
        <Routes>
          <Route path="/*" element={<Frontpage />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/flow" element={<Flow />} />
          <Route path="/portofolio" element={<Portofolio />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/dustjacket/*" element={<Dustjacket />} />
        </Routes>
      </HashRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);