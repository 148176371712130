import React from 'react'
import './index.css';
import App from './App';
import './fonts/Emberly[wdth,wght].ttf'
function Dustjacket() {
  return (
    <App />
  );
}
export default Dustjacket; 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
